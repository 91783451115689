import Vue from "vue";
import { DISABLE_COUNTRY_INTERCEPTOR } from "@/common/interceptor-helpers";

/**
 * Liquibase ChangeSet: createDynamicPropertiesTable
 *
 * Describes the structure of the 'dynamic_properties' table.
 *
 * @typedef {Object} DynamicPropertiesTable
 * @property {number} id - The unique identifier for each record. Auto-incremented.
 * @property {string} property_group - The group to which the property belongs (VARCHAR, max length: 255).
 * @property {string} property_name - The name of the property (VARCHAR, max length: 255). Not nullable.
 * @property {string} property_value - The value of the property.
 * @property {string} value_type - The data type of the property value (VARCHAR, max length: 50).
 * @property {number} city_id - The identifier of the associated city (BIGINT).
 * @property {Date} created_at - The timestamp of when the record was created (TIMESTAMP). Not nullable.
 * @property {Date} valid_until - The timestamp indicating until when the property is valid (TIMESTAMP).
 * @property {Date} valid_from - The timestamp indicating from when the property is valid (TIMESTAMP).
 */

const SettingsApiService = {
  URL: "",

  init() {
    this.URL = process.env.VUE_APP_API_BASE;
  },

  GET_SETTINGS: "api/settings",
  DELETE_SETTING: id => `api/settings/${id}`,
  GET_SETTINGS_GROUP: groupName => `api/settings/${groupName}`,
  UPDATE_SETTING: id => `api/settings/update/${id}`,
  CREATE_SETTING: (groupName, propertyName) => `api/settings/${groupName}/${propertyName}`,

  updateSettingGroup(propertyGroup, body) {
    return Vue.axios.put(this.URL + this.GET_SETTINGS_GROUP(propertyGroup), body).catch(error => {
      throw new Error(`[KT] SettingsApiService ${error}`);
    });
  },
  updateSetting(id, body) {
    return Vue.axios.put(this.URL + this.UPDATE_SETTING(id), body).catch(error => {
      throw new Error(`[KT] SettingsApiService ${error}`);
    });
  },
  createSettingGroup(propertyGroup, body) {
    return Vue.axios.post(this.URL + this.GET_SETTINGS_GROUP(propertyGroup), body).catch(error => {
      throw new Error(`[KT] SettingsApiService ${error}`);
    });
  },
  getSettingsGroup(propertyGroup, params = {}) {
    const headers = {};

    if (params?.countryId) {
      headers["req-context"] = JSON.stringify({
        [DISABLE_COUNTRY_INTERCEPTOR]: true
      });
    }

    return Vue.axios
      .get(this.URL + this.GET_SETTINGS_GROUP(propertyGroup), { params, headers })
      .catch(error => {
        throw new Error(`[KT] SettingsApiService ${error}`);
      });
  },
  getSettings() {
    return Vue.axios.get(this.URL + this.GET_SETTINGS).catch(error => {
      throw new Error(`[KT] SettingsApiService ${error}`);
    });
  },
  deleteSetting(propertyGroup, ids = []) {
    return Vue.axios
      .delete(this.URL + this.GET_SETTINGS_GROUP(propertyGroup), { params: { ids } })
      .catch(error => {
        throw new Error(`[KT] SettingsApiService ${error}`);
      });
  }
};

export default SettingsApiService;
