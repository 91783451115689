import { render, staticRenderFns } from "./general-settings.vue?vue&type=template&id=e33ea01c"
import script from "./general-settings.component.js?vue&type=script&lang=js&external"
export * from "./general-settings.component.js?vue&type=script&lang=js&external"
import style0 from "./general-settings.vue?vue&type=style&index=0&id=e33ea01c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBtn,VCombobox,VDataTable,VForm,VIcon,VLayout,VList,VListItem,VMenu,VNavigationDrawer,VTextField})
