import { DISABLE_COUNTRY_INTERCEPTOR } from "@/common/interceptor-helpers";
import Vue from "vue";

const GeneralSettingsService = {
  URL: "",

  init() {
    this.URL = process.env.VUE_APP_API_BASE;
  },

  GET_SETTINGS_GROUP: groupName => `api/settings/${groupName}`,
  CREATE_SETTINGS_GROUP: (groupName, propertyName) => `api/settings/${groupName}/${propertyName}`,
  UPDATE_SETTING: id => `api/settings/update/${id}`,

  getGeneralSettingsGroup(propertyGroup, countryId) {
    const params = new URLSearchParams();
    params.append("countryId", countryId);

    return Vue.axios
      .get(this.URL + this.GET_SETTINGS_GROUP(propertyGroup), { params })
      .catch(error => {
        throw new Error(`[KT] GeneralSettingsService ${error}`);
      });
  },

  createSettingGroup(propertyGroup, propertyName, body) {
    const headers = {
      "req-context": JSON.stringify({
        [DISABLE_COUNTRY_INTERCEPTOR]: true
      })
    };

    return Vue.axios
      .post(this.URL + this.CREATE_SETTINGS_GROUP(propertyGroup, propertyName), body, { headers })
      .catch(error => {
        throw new Error(`[KT] GeneralSettingsService ${error}`);
      });
  },

  updateSettingGroup(id, body) {
    const headers = {
      "req-context": JSON.stringify({
        [DISABLE_COUNTRY_INTERCEPTOR]: true
      })
    };

    return Vue.axios.put(this.URL + this.UPDATE_SETTING(id), body, { headers }).catch(error => {
      throw new Error(`[KT] GeneralSettingsService ${error}`);
    });
  }
};

export default GeneralSettingsService;
